@import "variables.css";

@import "react-quill/dist/quill.snow.css";

body {
  margin: 0;
}

.no-space-form .ant-form-item {
  margin-bottom: 0;
}

.half-space-form .ant-form-item {
  margin-bottom: 0.75rem;
}

.capitalize {
  text-transform: capitalize;
}

a {
  color: #791169;
}

a:hover {
  color: #79116980;
}

.not-readed {
  background-color: #79116920;
}

.clickable:hover {
  background-color: #79116950;
}

.ql-toolbar {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.ql-container {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
